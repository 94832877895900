/* Gradient overlay effect */
.gradient-hover-card {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .gradient-hover-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(120deg, rgba(106, 174, 202, 0.8), rgba(255, 255, 255, 0.8));
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 0;
    border-radius: 10px;
  }
  
  .gradient-hover-card:hover::before {
    opacity: 1;
  }
  
  .gradient-hover-card > * {
    position: relative;
    z-index: 1;
  }
 
.gradient-border {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
  }
  
  .gradient-border::before {
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 12px;
    padding: 2px;
    background: linear-gradient(
      90deg,
      #6aaeca,
      #1f488e,
      #ffffff,
      #1f488e,
      #6aaeca
    );
    background-size: 200% 200%;
    animation: gradient-border-animation 2s linear infinite;
    z-index: -1;
  }
  
  .gradient-border > img {
    position: relative;
    border-radius: 10px; 
    z-index: 1;
  }
  

  @keyframes gradient-border-animation {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  