@keyframes gradient-border {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 50% 100%;
  }
}

.animate-gradient-border {
  animation: gradient-border 1s linear infinite;
  background-size: 300% 300%;
}
