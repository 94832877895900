@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .bg-gradient-border {
    background: linear-gradient(
      90deg,
      #6aaeca,
      #1f488e,
      #ffffff,
      #1f488e,
      #6aaeca
    );
    background-size: 200% 200%;
  }
  .animate-gradient-border {
    animation: gradientBorderAnimation 5s linear infinite;
  }
  @keyframes gradientBorderAnimation {
    0%,
    100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
}
