@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Lexend+Deca:wght@100..900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
 
  list-style: none;
  text-decoration: none !important;
  font-family: "Lexend Deca", sans-serif !important;
  scroll-behavior: smooth !important;
}
@keyframes circularGradientAnimation {
  0% {
    background-position: 50% 0%;
  }
  25% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 50% 100%;
  }
  75% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.animate-background {
  animation: circularGradientAnimation 15s ease-in-out infinite;
  background: linear-gradient(
    120deg,
    #6aaeca 0%,
    #1f488e 25%,
    #727272 50%,
    #1f488e 75%,
    #6aaeca 100%
  );
  background-size: 400% 400%;
  position: relative;
  overflow: hidden;
}
.accordion-content {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transform: scale(0.95);
  transition: max-height 0.4s ease, opacity 0.5s ease, transform 0.5s ease;
}
.accordion-content.open {
  max-height: 500px;
  opacity: 1;
  transform: scale(1);
}
